<template>
	<div class="oa-search" :class="{ 'oa-search--show': show }">
		<header class="oa-search__header">
			<div class="oa-search__navigation">
				<IconButton
					class="oa-icon-button--close oa-icon-button--back"
					@click="closeSearch()"
				/>
				<p class="oa-search__title oa-search__title--plain">{{ data && data.name  }}<span class="oa-search__title__subtitle">{{ this.currentLanguage.products }}: <data  class="oa-search__title__num" :value="products.length">{{ products.length }}</data></span></p>
			</div>
			<div class="oa-search__input-wrapper">
				<IconButton
					v-if="hasInputValue"
					class="oa-icon-button--close oa-icon-button--search"
					@click="resetSearch()"
				/>
				<Input
					ref="search"
					v-model="inputValue"
					class="oa-input--search"
					:placeholder="placeholder"
				/>
			</div>
		</header>
		<main
			class="oa-main oa-main--search"
			:class="{ 'oa-main--height': !hasInputValue }"
		>
			<ul class="oa-list">
				<li class="oa-list__item" v-for="(item, ind) in filteredProducts" :key="ind" @click="$emit('openProperties', item)">
				<Icon class="oa-icon--flask" />
				<div class="oa-list__item__content">
					<h3 class="oa-list__item__headline">{{ item.artNo }}</h3>
					<p class="oa-list__item__name">{{ item.name }}</p>
				</div>
				</li>
			</ul>
			<ResultsInfo
				v-if="hasInputValue && tabs[currentIndex].numberOfItems === 0"
				:title="currentLanguage.noResults"
				:text="currentLanguage.tryAdjustingSearch"
				img="https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/search-results.svg"
			/>
		</main>
	</div>
	<Properties
		v-if="showProperties"
		:data="propertiesData"
		@close="closeProperties()"
	/>
</template>

<script>
	import Icon from "@components/common/Icon.vue";
	import IconButton from "@components/common/IconButton.vue";
	import Input from "@components/common/Input.vue";
	import Properties from "@components/common/Properties.vue";
	import ResultsInfo from "@components/ResultsInfo.vue";
	import useTranslations from "@modules/translations.js";

	export default {
		name: "Search",
		setup() {
			const { currentLanguage } = useTranslations();
			return { currentLanguage };
		},

		props:{
			"products": Array,
			"placeholder": String,
			"show": Boolean,
			"data": Object
		},
		emits: ["closeSearch", "openProperties"],
		computed: {
			tabs() {
				var result = [
					{name: this.currentLanguage.products, numberOfItems: this.filteredProducts.length}
				];

				return result;
			},
			filteredProducts() {
				return (this.products || []).filter(this.filterFunction);
			},		
			hasInputValue() {
				return this.inputValue.length > 0;
			},
			allFilteredData() {
				const allData = [...this.products];
				return allData.sort((a, b) => {
					// Safely handle cases where a.name or b.name might be null or undefined
					const nameA = (a && a.name) ? a.name : ''; // Fallback to empty string if a.name is null/undefined
					const nameB = (b && b.name) ? b.name : ''; // Fallback to empty string if b.name is null/undefined
					return nameA.localeCompare(nameB);
				}).filter(this.filterFunction);
			},
		},
		data() {
			return {
				showProperties: false,
				propertiesData: null,
				inputValue: "",
				currentTab: this.currentLanguage.all,
				currentIndex: 0
			};
		},
		components: {
			Icon,
			IconButton,
			Input,
			Properties,
			ResultsInfo
		},
		methods: {
			openProperties(item) {
				this.showProperties = true;
				this.propertiesData = item;
			},
			closeProperties() {
				this.showProperties = false;
				this.propertiesData = null;
			},
			resetSearch() {
				this.inputValue = "";
			},

			filterFunction(x) {
				return (x.name || "").toUpperCase().includes(this.inputValue.toUpperCase()) ||
					(x.artNo || "").toUpperCase().includes(this.inputValue.toUpperCase()) ||
					(x.productName || "").toUpperCase().includes(this.inputValue.toUpperCase());
			},
			closeSearch() {
				this.$emit("closeSearch");
				this.resetSearch();
			},
			handleSwitchTab(tabName, index) {
				this.currentTab = tabName;
				this.currentIndex = index;
			},
			focusSearch() {
				const inputReference = this.$refs.search;
				inputReference.focusInput();
``			},
		},
	};
</script>

<style>
	.oa-search {
		opacity: 0;
		pointer-events: none;
		position: absolute;
		width: 100%;
		max-width: 1054px;
		height: 100%;
		left: 50%;
		translate: -50% 0;
		background-color: var(--color-gray-100);
		scale: 0.95;
		transition: all 0.2s ease-out;
		z-index: -1;
	}

	.oa-search--show {
		opacity: 1;
		scale: 1;
		z-index: 10;
		pointer-events: all;
		transition: all 0.15s ease-out;
	}


	.oa-search__header {
		margin-top: 24px;
		padding: 0 16px 3px;
	}

	.oa-search__navigation {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 24px;
	}

	.oa-search__title {
		flex: 1;
		text-align: center;
		color: var(--color-gray);
		font-size: 16px;
		line-height: 24px;
		font-weight: 700;
	}

	.oa-search__title--plain {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.oa-search__title__subtitle {
		color: var(--color-gray-500);
		font-size: 14px;
		font-weight: 400;
		font-weight: 600;
		line-height: 20px
	}

	.oa-search__title__num {
		font-weight: 600;
	}

	.oa-search__results {
		width: 215px;
		margin: 96px auto 0;
		padding-top: 55px;
		background-image: url(https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/search-results.svg);
		background-position: center top;
		background-repeat: no-repeat;
		text-align: center;
	}

	.oa-search__results__title {
		color: var(--color-gray-900);
		font-weight: 900;
		font-size: 16px;
		line-height: 24px;
		margin: 22px 0 0;
	}

	.oa-search__results__text {
		margin-top: 8px;
		color: var(--color-gray-700);
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
	}

	.oa-search__input-wrapper {
		position: relative;
	}

	.oa-main.oa-main--search {
		max-height: calc(100% - 122px);
	}

	.oa-main.oa-main--search .oa-list:has(.oa-list__item) {
		margin-top: 16px;
	}

	.oa-main.oa-main--height {
		height: 100%;
	}

	.oa-icon-button--search {
		position: absolute;
		right: 16px;
		top: 50%;
		translate: 0 -50%;
		z-index: 10;
	}

	.oa-input {
		font-family: "Inter";
		position: relative;
		box-sizing: border-box;
		padding: 16px 16px 16px 48px;
		border-radius: 12px;
		width: 100%;
		border: none;
		outline: 1px solid var(--color-gray-300);
		color: var(--color-gray-900);
		font-size: 14px;
		font-weight: 500;
		isolation: isolate;
	}

	.oa-input::placeholder {
		margin-bottom: 0;
		color: var(--color-pale);
		font-size: 14px;
		font-weight: 400;
	}
</style>